<template>
  <div class="root">
    <div class="nav flex_r">
      <img src="@/assets/logo.png" alt="">
      <span>{{$t('演示站') }} </span>
    </div>
    <div class="listWrap flex_r">
      <!-- <div class="list" :class="{active:selectIndex==0}" @click="selectIndex=0">{{$t('包网')}}H5</div>
      <div class="list" :class="{active:selectIndex==1}" @click="selectIndex=1">{{$t('包网')}}PC</div>
      <div class="list" :class="{active:selectIndex==2}" @click="selectIndex=2">{{$t('包网后台代理')}}</div> -->
      <div class="list" :class="{active:selectIndex==0}" @click="selectIndex=0">{{$t('手机模板')}}</div>
      <div class="list" :class="{active:selectIndex==1}" @click="selectIndex=1">{{$t('PC端模板')}}</div>

    </div>
    <div class="flex_r ysz" v-if="selectIndex==0">
      <div v-for="(item,index) in allList[selectIndex].list" class="yszList" @click="itemClick(item)">
        <img :src="item.img" alt="">
        <div class="word1">WAP {{$t(item.label1)}} {{Number(index)  + 1}}</div>
        <div class="word2">{{item.label2}}</div>
      </div>
    </div>
    <div class="flex_r ysz" v-if="selectIndex==1">
      <div v-for="(item,index) in allList[selectIndex].list" class="yszList" @click="itemClick(item)">
        <img :src="item.img" alt="">
        <div class="word1">Web {{$t(item.label1)}} {{Number(index)  + 1}}</div>
        <div class="word2">{{item.label2}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import i18n from "@/locales/i18n";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {
      selectIndex: 0,
      allList: [
        {
          list: [
            {
              img: require('@/assets/01mb.png'),
              label1: '手机模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m11/'
            },
            {
              img: require('@/assets/02_mb.png'),
              label1: '手机模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m21/'
            },
            {
              img: require('@/assets/03_mb.png'),
              label1: '手机模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m31/'
            }
          ]
        },
        {
          list: [
            {
              img: require('@/assets/01dianm.png'),
              label1: 'PC端模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m11/'
            },
            {
              img: require('@/assets/02_pc.png'),
              label1: 'PC端模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m21/'
            },
            {
              img: require('@/assets/03_pc.png'),
              label1: 'PC端模板',
              label2: 'K8',
              link: 'https://template.K8gaming.uk/m31/'
            }
          ]
        }
      ]
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {

  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),

    itemClick(item) {
      window.open(item.link);
    }
  },
};
</script>

<style scoped lang="scss">
.root {
  //width:100vw;
  min-height: 100vh;
  background: url(@/assets/bg.svg);
  background-size: 100%;
  padding-bottom: 50px;
  background-attachment: fixed;
  background-size: cover;
  //  overflow: hidden;
}
.ysz {
  margin: 0 auto;

  margin-top: 55px;
  justify-content: center;
  flex-wrap: wrap;
  width: 1104px;
  .yszList {
    text-align: center;
    margin-left: 33px;
    font-weight: 400;
    cursor: pointer;
    .word1 {
      color: #18badf;
      font-size: 20px;
      margin-top: 20px;
    }
    .word2 {
      color: #fff;
      font-size: 12px;
      margin-top: 0px;
    }
    img {
      width: 346px;
    }
  }
  .yszList:nth-child(3n + 1) {
    margin-left: 0;
  }
  .yszList:nth-child(n + 4) {
    margin-top: 47px;
  }
}
.listWrap {
  padding-top: 160px;
  justify-content: center;

  .list {
    font-size: 14px;
    color: #848795;
    cursor: pointer;
    font-weight: 400;
  }
  .list:hover {
    color: #fff;
  }
  .active {
    color: #fff;
  }
  .list + .list {
    margin-left: 40px;
  }
}
.nav {
  height: 96px;
  position: fixed;
  width: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  background: #2496e74a !important;
  img {
    height: 80%;
    object-fit: cover;
  }
  span {
    color: #fff;
    font-size: 28px;
    margin-left: 10px;
    font-weight: 400;
  }
}
</style>

