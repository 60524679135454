<template>
  <div class="kheader flex_c" :style="showAlpha?'background: rgba(0, 0, 0, 0.61)':'background: rgb(0, 0, 0)'">
    <img src="@/assets/logo.png" class="logo" alt="" />
    <div class="flex_r">
      <div v-for="(item, index) in navList" :key="item" class="navItem" @click="listClick(item)">
        {{$t( item.title) }}
      </div>
      <div class="lang-toggle flex_r" @mouseenter="showLang=true" @mouseleave="showLang=false">
        <div class="flex_r" style="margin-top:15px;">
          <img src="@/assets/english_gq.png" v-if="$i18n.locale=='en'" alt="" />

          <img src="@/assets/yuenan_gq.png" v-if="$i18n.locale=='cn'" alt="" />

          <span v-if="$i18n.locale=='cn'">{{$t('中文') }}</span>
          <span v-if="$i18n.locale=='en'">{{ $t('英文') }}</span>
          <img src="@/assets/arrow.png" class="arrow" alt="" />
        </div>
        <div class="lang" v-if="showLang" @mouseenter="showLang=true" @mouseleave="showLang=false">
          <div class="flex_r canClick" @click="changeLan('cn')" style="width:100%;">
            <img src="@/assets/chinese_gq.png" alt="">
            <span>{{$t('中文')}}</span>
          </div>
          <div class="flex_r canClick" @click="changeLan('en')" style="margin-top:10px;">
            <img src="@/assets/english_gq.png" alt="">
            <span>{{$t('英文')}}</span>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import i18n from "@/locales/i18n";

export default {
  components: {},
  data() {
    return {
      langList: [
        { title: "English", lang: "en" },
        { title: "Tiếng Việt", lang: "vi" },
        { title: "简体中文", lang: "cn" },
      ],
      langIndex: 0,
      oldScrollTop: 0,
      showAlpha: true,
      showLang: false,
      navIndex: 0,
      navList: [
        { title: "主页", to: "home" },
        { title: "产品", to: "product" },
        { title: "案例演示", out: "demo" },
        { title: "服务", ele: "services" },
        { title: "关于我们", ele: "about" },
        { title: "联系我们", ele: "client" },
        { title: "合作伙伴", ele: "client" },

      ],
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    window.addEventListener("scroll", this.scrolling);
    if (localStorage.getItem("language")) {
      for (var i = 0; i < this.langList.length; i++) {
        if (this.langList[i].lang == localStorage.getItem("language")) {
          this.langIndex = i;
        }
      }
    }

    if (this.$route.path == "/products") {
      this.showAlpha = false
    } else {
      this.showAlpha = true
    }
  },
  watch: {

    $route(to, from) {
      if (to.path == "/products") {
        this.showAlpha = false
      }
      else {
        this.showAlpha = true
      }
    },
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    changeLan(lan) {
      this.$i18n.locale = lan
      this.$forceUpdate()
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离


      //变量windowHeight是可视区的高度
      if (document.getElementById('home')) {
        let bannerHeight = document.getElementById('home').clientHeight
        if (scrollTop + 100 >= bannerHeight) {
          this.showAlpha = false
        } else {
          this.showAlpha = true

        }
      } else {
        this.showAlpha = false
      }


    },

    listClick(item) {
      if (item.ele) {
        if (this.$route.path != '/') {
          this.$router.push('/')
          setTimeout(() => {
            this.toElement(item.ele);
          }, 50);
        } else {
          this.toElement(item.ele);

        }
      }

      if (item.out) {
        window.open('/#/' + item.out)
        return
      }

      if (item.to) {
        this.$router.push({
          name: item.to,

        });
      }
    },
    toElement(element) {
      var scrollNum = this.getElementTop(element);
      document.documentElement.scrollTo({
        top: scrollNum - 100,
        behavior: "smooth",
      });
    },
    getElementTop(element) {
      var el =
        typeof element == "string" ? document.getElementById(element) : element;
      return el.offsetTop;
    },
    toHome() {
      this.navIndex = 0;
      this.$router.push("/");
    },
    toYs() {
      this.$router.push("/");
      setTimeout(() => {
        document.documentElement.scrollTo({ top: 2700 });
      }, 100);
    },
    toPage(val, index) {
      if (val.to.length) {
        this.navIndex = index;
        this.$router.push({
          name: val.to,
          params: {
            type: val.type,
          },
        });
      }
    },
    toContact() {
      this.$router.push("/ContactUs");
    },
    change(e) {
      localStorage.setItem("language", e.target.value);
      i18n.global.locale = e.target.value;
      for (var i = 0; i < this.langList.length; i++) {
        var obj = this.langList[i];
        if (e.target.value == obj.lang) {
          this.langIndex = i;
          break;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.kheader {
  height: 100px;
  padding: 0 98px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  .lang {
    position: absolute;
    right: 0;
    top: 98px;
    border: 1px solid #fff;
    //padding:20px 10px;
    padding: 8px 0;
    box-sizing: border-box;
    width: 100px;
    background-color: #000;
    span {
      color: #fff;
      font-size: 13px;
      margin-left: 12px;
    }
  }
  .arrow {
    width: 15px;
  }
  .lang-toggle {
    height: 100px;
    position: relative;
    // margin-top: 15px;
    span {
      //line-height: 120px;
      color: #fff;
      margin: 0 5px;
    }
  }
  .navItem {
    color: white;
    height: 100px;
    font-size: 15px;
    line-height: 120px;
    padding: 0 19px;
    overflow: hidden;
    cursor: pointer;
  }
  .navItem:hover {
    background-color: #0091ea;
  }
  .logo {
    height: 80%;
    object-fit: cover;
  }
}
</style>