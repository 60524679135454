<template>
  <div style="padding-top:0px;">
    <section id="home" class="banner-section">
      <div class="banner-btn banner-btn-left"><img src="@/assets/pc/home/arrowLeft.png" alt="" @click="movePage(0)" style="width:30px;"></div>
      <div class="banner-btn banner-btn-right"><img src="@/assets/pc/home/arrowRight.png" alt="" @click="movePage(1)" style="width:25px;"></div>
      <div class="banner-container" :style="{backgroundImage:'url('+ bgList[curIndex]+')'}">
        <div class="banner-content banner-slide-1" :style="curIndex!=0?'display:none;':''">
          <div class="tag-sec">
            <div class="row over-hidden banner-text">
              <div class="col-12">
                <p class="txt-xlg banner-name wow fadeInDown" data-wow-duration="1.5s"><span>{{$t('为您提供')}}</span> {{$t('全方位博弈系统')}}</p>
                <p class="banner-name-2 wow fadeInDown" data-wow-duration="1.5s"><span>&nbsp;{{$t('解决方案')}}</span></p>
              </div>
            </div>
            <hr class="wow  bounceIn" data-wow-duration="3s">
            <h6 class="wow fadeInDown" data-wow-duration="3.5s">{{$t('专注于为客户提供安全、稳定、先进的产品，我们的成功源于不断的创新，并致力于资源整合。')}}</h6>
            <div class="read-more">
              <p class="wow fadeInDown" data-wow-duration="4s"> <img src="@/assets/arrow.png" style="width:15px;margin-right:5px;" alt="" />Read More</p>
            </div>
            <img class="icon1 wow bounceInLeft" data-wow-duration="2s" src="@/assets/pc/home/10003.png" alt="">
            <img class="icon2 wow bounceInLeft" data-wow-duration="2s" src="@/assets/pc/home/10004.png" alt="">
            <img class="icon3 wow bounceInRight" data-wow-duration="2.2s" src="@/assets/pc/home/10005.png" alt="">
            <img class="icon4 wow bounceInLeft" data-wow-duration="2.2s" src="@/assets/pc/home/10006.png" alt="">
            <img class="icon5 wow bounceInLeft" data-wow-duration="1.8s" src="@/assets/pc/home/10007.png" alt="">
            <img class="icon7 wow bounceInRight" data-wow-duration="1.2s" src="@/assets/pc/home/10008.png" alt="">
            <img class="icon8 wow bounceInRight" data-wow-duration="2s" src="@/assets/pc/home/10009.png" alt="">
            <img class="icon9 wow bounceInRight" data-wow-duration="1.8s" src="@/assets/pc/home/10010.png" alt="">
          </div>
        </div>
        <div class="banner-content banner-slide-2" :style="curIndex!=1?'display:none;':''">
          <div class="row text4-text">
            <div class="col-12 txt-center">
              <p class="slide4-title wow bounceInDown" data-wow-duration="1.5s">{{$t('为您提供')}} <span>{{$t('最佳的')}}</span> {{$t('游戏表现')}}</p>
            </div>
          </div>
          <div class="row text4-image over-hidden">
            <div class="col-12 txt-center">
              <img class="slide4-image wow slideInLeft" src="@/assets/pc/home/10011.png">
            </div>
          </div>
        </div>
        <div class="banner-content banner-slide-3" :style="curIndex!=2?'display:none;':''">
          <div class="row over-hidden">
            <div class="col-9 no-padding slide2-title">
              <div class="row over-hidden">
                <div class="col-2 no-padding txt-right" style="display:inline-block;">
                  <img class="html-logo inline wow zoomIn" data-wow-duration="1.5s" src="@/assets/pc/home/10012.png" alt="">
                </div>
                <div class="col-10 no-padding slide2-text ">
                  <p class="slide2-name inline wow bounceInDown" data-wow-duration="1.5s"><span>HTML5</span> {{$t('极致')}}</p>
                  <p class="slide2-subname inline wow bounceInDown" data-wow-duration="1.5s"> {{$t('体验')}}</p>
                </div>
              </div>
            </div>
          </div>
          <img class="ph1 wow slideInUp" data-wow-duration="1.5s" src="@/assets/pc/home/10013.png" alt="">
          <img class="ph3 wow slideInUp" data-wow-duration="1.5s" src="@/assets/pc/home/10014.png" alt="">
          <img class="ph2 wow slideInUp" data-wow-duration="2.2s" src="@/assets/pc/home/10015.png" alt="">
        </div>
        <div class="banner-content banner-slide-4" :style="curIndex!=3?'display:none;':''">
          <img class="slide3-icon wow zoomIn" data-wow-duration="2.5s" src="@/assets/pc/home/10016.png" alt="">
          <div class="row text3-text">
            <div class="col-12 txt-center">
              <p class="slide3-title wow bounceInDown" data-wow-duration="1.5s">{{$t('各大主流')}} </p>
              <p class="slide3-title wow bounceInDown" data-wow-duration="1s"><span>API</span> {{$t('无缝集成')}}</p>
            </div>
          </div>
          <div class="text3-image">
            <img class="table wow slideInLeft" data-wow-duration="1s" src="@/assets/pc/home/10017.png" alt="">
            <img class="laptop wow slideInRight" data-wow-duration="1s" src="@/assets/pc/home/10018.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <section id="products">
      <div class="container add-padding-container">
        <div class="row">
          <div class="mainTitle">
            <h1 class=" bounceInDown" data-wow-duration="1s">{{$t('我们的产品')}}</h1>
            <hr>
            <h5>{{$t('我们提供什么')}}?</h5>
          </div>
        </div>
        <div class="row over-hidden">
          <div class="col-3 over-hidden creation-col-6 padding-side wow zoomIn" data-wow-duration="1s" data-wow-delay=".2s">
            <div class="creation slots" onclick="if (!window.__cfRLUnblockHandlers) return false; location.href='products-ch.html'">
              <img class="creation-image" src="@/assets/pc/home/10019.jpg" alt="">
              <div class="text-content">
                <h2>{{$t('电子游戏')}}</h2>
                <p>{{$t('home1')}}</p>
              </div>
            </div>
          </div>
          <div class="col-3 over-hidden creation-col-6 padding-side wow zoomIn" data-wow-duration="1s" data-wow-delay=".4s">
            <div class="creation poker" onclick="if (!window.__cfRLUnblockHandlers) return false; location.href='products-ch.html'">
              <img class="creation-image" src="@/assets/pc/home/10020.jpg" alt="">
              <div class="text-content">
                <h2>{{$t('扑克')}}</h2>
                <p>{{$t('home2')}}</p>
              </div>
            </div>
          </div>
          <div class="col-3 over-hidden creation-col-6 padding-side wow zoomIn" data-wow-duration="1s" data-wow-delay=".6s">
            <div class="creation sports" onclick="if (!window.__cfRLUnblockHandlers) return false; location.href='products-ch.html'">
              <img class="creation-image" src="@/assets/pc/home/10021.jpg" alt="">
              <div class="text-content">
                <h2>{{$t('体育')}}</h2>
                <p>{{$t('home3')}}</p>
              </div>
            </div>
          </div>
          <div class="col-3 over-hidden creation-col-6 padding-side wow zoomIn" data-wow-duration="1s" data-wow-delay=".8s">
            <div class="creation live-game" onclick="if (!window.__cfRLUnblockHandlers) return false; location.href='products-ch.html'">
              <img class="creation-image" src="@/assets/pc/home/10022.jpg" alt="">
              <div class="text-content">
                <h2>{{$t('现场游戏')}}</h2>
                <p>{{$t('home4')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="services" class="service-banner">
      <div class="container">
        <div class="row">
          <div class="mainTitle service-Title">
            <h1 class="wow bounceInDown" data-wow-duration="1s">{{$t('提供服务')}}</h1>
            <hr>
            <h5 class="txt-wht">{{$t('我们能为您做什么')}}?</h5>
          </div>
        </div>
        <div class="row service-margin">
          <div class="col-4 no-padding services-col-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".2s">
            <div class="services">
              <div class="services-cont no-margin">
                <img class="img-hover" src="@/assets/pc/home/10023.jpg" alt="">
                <div class="service-text">
                  <h2>{{$t('运营支援')}}</h2>
                  <p>{{$t('home5')}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 no-padding services-col-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".4s">
            <div class="services">
              <div class="services-cont no-margin" style="">
                <img class="img-align img-hover" src="@/assets/pc/home/10024.jpg" alt="">
                <div class="service-text">
                  <h2>{{$t('客户服务')}}</h2>
                  <p>{{$t('home6')}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 no-padding services-col-4 wow zoomIn" data-wow-duration="1s" data-wow-delay=".6s">
            <div class="services">
              <div class="services-cont no-margin" style="">
                <img class="img-align img-hover" src="@/assets/pc/home/10025.jpg" alt="">
                <div class="service-text">
                  <h2>{{$t('技术支援')}}</h2>
                  <p>{{$t('home7')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" class="about">
      <div class="container over-hidden">
        <div class="row over-hidden">
          <div class="col-12">
            <div class="company txt-center mainTitle">
              <h1 class="wow bounceInDown" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: bounceInDown;">New Gaming</h1>
              <hr>
              <h5 class="company-subtitle">{{$t('K8简介')}}</h5>
              <div class="company-nav">
                <div class="row over-hidden">
                  <div data-nav-name="tab1" class="col-3 comtab " @click="aboutIndex=0" :class="aboutIndex==0?'company-nav-active':''">
                    <h5>{{$t('关于我们')}}</h5>
                  </div>
                  <div data-nav-name="tab2" class="col-3 comtab" @click="aboutIndex=1" :class="aboutIndex==1?'company-nav-active':''">
                    <h5>{{$t('我们的使命')}}</h5>
                  </div>
                  <div data-nav-name="tab3" class="col-3 comtab" @click="aboutIndex=2" :class="aboutIndex==2?'company-nav-active':''">
                    <h5>{{$t('我们的愿景')}}</h5>
                  </div>
                  <div data-nav-name="tab4" class="col-3 comtab" @click="aboutIndex=3" :class="aboutIndex==3?'company-nav-active':''">
                    <h5>{{$t('我们的宗旨')}}</h5>
                  </div>
                </div>
                <hr class="company-nav-line">
              </div>
              <div class="company-content wow zoomIn" data-wow-duration="1s" data-wow-delay=".2s">
                <span class="company-icon-left"><img src="@/assets/pc/home/yinhaoyou.png" alt=""></span>
                <span class="company-icon-right"><img src="@/assets/pc/home/yinhaozuo.png" alt=""></span>
                <div class="com-contents" :style="aboutIndex!=0?'display:none;':''">
                  <p>{{$t('home8')}} </p>

                </div>
                <div class="com-contents " :style="aboutIndex!=1?'display:none;':''">
                  <p>{{$t('home9')}}</p>

                </div>
                <div class="com-contents " :style="aboutIndex!=2?'display:none;':''">
                  <p>{{$t('home10')}} </p>

                </div>
                <div class="com-contents " :style="aboutIndex!=3?'display:none;':''">
                  <p class="no-margin">{{$t('home11')}} </p>
                  <p>{{$t('home12')}} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="client" class="client-container">
      <div class="container over-hidden">
        <div class="row over-hidden">
          <div class="col-12">
            <div class="client mainTitle">
              <h1 class="wow bounceInDown" data-wow-duration="1s">{{$t('合作伙伴')}}</h1>
              <hr>
              <h5 style="margin-bottom:50px;">{{$t('我们的荣誉合作伙伴')}}</h5>
              <swiper style="margin-bottom:60px;" :modules="modules" :autoplay="{
        autoplay: true,
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }" :free-mode="true" :slides-per-view="4" :slides-per-group="1" :space-between="0">
                <swiper-slide ref="swipeSlideRef" v-for="(item, index) in factoryList" :key="item">
                  <img ref="swipeSlideImgRef" style="width:150px;" :src="item" alt="" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper";
import { Autoplay } from "swiper";
import {
  WOW
} from 'wowjs';
import "swiper/swiper.css";
export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      curIndex: 0,
      factoryList: [],
      modules: [FreeMode, Autoplay],
      aboutIndex: 0,
      bgList: [require('@/assets/pc/home/10042.jpg'), require('@/assets/pc/home/10043.jpg'), require('@/assets/pc/home/10047.jpg'), require('@/assets/pc/home/10048.jpg')],
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    for (var i = 1; i <= 20; i++) {
      let img = require("@/assets/pc/factory/" + i + ".png");
      this.factoryList.push(img);
    }
    this.$nextTick(() => { //页面渲染完，在执行
      let wow = new WOW({
        live: false
      })
      wow.init()
    })
    setInterval(() => {
      if (this.curIndex == 3) {
        this.curIndex == 0
      } else {
        this.curIndex++
      }
    }, 8000);
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    movePage(type) {
      if (type == 0) {
        if (this.curIndex == 0) {
          this.curIndex = 3
        } else {
          this.curIndex--
        }
      } else {
        if (this.curIndex == 3) {
          this.curIndex = 0
        } else {
          this.curIndex++
        }
      }
    },
    toPage(name) {
      this.$router.push({ name: name })
    },
    getElementTop(element) {
      // var scrollNum = that.getElementTop(element)
      // document.documentElement.scrollTo({top:scrollNum - 110,behavior: 'smooth'})

      var el = (typeof element == "string") ? document.getElementById(element) : element;

      return el.offsetTop
    },

  },
};
</script>

<style scoped lang="scss">
.swiper,
swiper-container {
  display: block;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}

.about {
  display: block;
  height: 520px;
}

.company-nav-active {
  border-bottom: 3px solid #0091ea;
}
.client-slider {
  margin-top: 50px;
}
.company {
  padding: 80px 50px;
  .company-content {
    position: relative;
    padding: 20px 100px;
    font-size: 12px;
    font-family: microsoft yahei;
    font-style: italic;
    .com-contents {
      margin-top: 25px;
      animation: zoomIn ease 0.5s;
      -webkit-animation: zoomIn ease 0.5s;
      -moz-animation: zoomIn ease 0.5s;
      -ms-animation: zoomIn ease 0.5s;
      -o-animation: zoomIn ease 0.5s;

      p {
        font-size: 15px;
      }
    }
    @keyframes zoomIn {
      0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
      }
      50% {
        opacity: 1;
      }
    }
    .company-icon-right {
      position: absolute;
      right: 50px;
      bottom: 30px;
      img {
        width: 25px;
      }
    }
    .company-icon-left {
      position: absolute;
      left: 50px;
      top: 10px;
      img {
        width: 25px;
      }
    }
  }
  .company-nav {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    .company-nav-line {
      width: 100%;
      margin: -2px;
      border: 2px solid #c7c8c9;
      background-color: #c7c8c9;
    }
    .comtab {
      cursor: pointer;
      > h5 {
        font-weight: 600;
      }
    }
  }
  .company-subtitle {
    margin: 0 0 30px;
    font-style: italic;
  }
}
@media (min-width: 200px) and (max-width: 991px) {
  .company {
    padding: 50px 0;
    .company-content {
      padding: 30px 0;
      .company-icon-right {
        right: 0;
        bottom: 0;
      }
      .company-icon-left {
        left: 0;
        top: 10px;
      }
    }
  }
}

.service-banner {
  background: url(@/assets/pc/home/10043.jpg) center no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  min-height: 600px;
  overflow: hidden;
  padding: 80px 0;
}
.add-padding-container {
  padding: 80px 15px;
}
.txt-wht {
  color: #fff !important;
}
.service-margin {
  margin-top: 21px;
}
.creation {
  cursor: pointer;
  text-align: center;
  height: 420px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  transition: all 300ms ease;
  .text-content {
    padding: 5px 10px;
    > p {
      color: #fff;
      font-size: 12px;
    }
    > h2 {
      color: #fff;
    }
  }
  .creation-image {
    width: 100%;
  }
}
.slots {
  background-color: #de9800;
}
.poker {
  background-color: #013e1e;
}
.sports {
  background-color: #0e1017;
}
.live-game {
  background-color: #cc0700;
}
.mainTitle {
  text-align: center;
  padding-bottom: 25px;
  > h5 {
    font-style: italic;
    padding: 0;
    line-height: 20px;
    font-weight: 600;
    color: #757575;
  }
  > hr {
    width: 25px;
    background-color: #0091ea;
    border-top: 2px solid #0091ea;
    border-bottom: 2px solid #0091ea;
    margin: 20px auto;
  }
  > h1 {
    color: #0091ea;
    padding: 0;
    margin: 0;
    font-weight: 600;
  }
}

.text3-text {
  margin-top: 120px;
  overflow: hidden;
}
.text3-image {
  position: relative;
  width: 80%;
  .laptop {
    position: absolute;
    left: 25%;
    top: 60px;
  }
  .table {
    position: absolute;
    left: 20%;
    top: 450px;
  }
}
.slide3-title {
  color: #fff;
  font-family: microsoft yahei, sans-serif;
  font-weight: 400;
  font-size: 60px;
  margin: 0;
  padding: 0;
  line-height: normal;
  > span {
    font-family: roboto-med;
  }
}
.services {
  padding: 0 11px;
  .services-cont {
    background-color: #fff;
    display: block;
    overflow: hidden;
    height: 379px;
    margin-bottom: 15px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
    .service-text {
      display: block;
      height: auto;
      padding: 0 5px;
      > p {
        font-family: microsoft yahei;
        text-align: center;
        font-weight: 400;
        font-size: 13px;
        padding: 0 10px;
      }
      > h2 {
        text-align: center;
        line-height: 34px;
        font-weight: 600;
      }
    }
    > img {
      width: 100%;
    }
  }
}
.slide2-text {
  position: relative;
  display: inline-block;
  margin-top: 50px;
  vertical-align: top;
  padding-left: 10px;
  .slide2-name {
    font-family: microsoft yahei, sans-serif;
    font-weight: 400;
    > span {
      font-family: roboto-med;
      font-size: 80px;
    }
  }
  > p {
    color: #fff;
    font-size: 50px;
    margin: 0;
    line-height: normal;
    display: inline-block;
  }
}
.slide4-image {
  width: 60%;
}
.text4-text {
  overflow: hidden;
  margin-top: 130px;
}
.slide2-title {
  margin: 0 auto;
  float: none;
  margin-top: 11%;
}
.html-logo {
  max-width: 65%;
}
.slide3-icon {
  position: absolute;
  left: 5%;
  top: 0;
}
.slide4-title {
  color: #fff;
  font-family: microsoft yahei, sans-serif;
  font-weight: 400;
  font-size: 60px;
  margin: 0;
  padding: 0;
  line-height: normal;
  > span {
    font-family: microsoft yahei, sans-serif;
    font-weight: 7;
  }
}
.banner-section {
  height: 1080px;
  position: relative;
  .banner-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: -8px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 1s ease;
  }
  .banner-btn-left {
    left: 10px;
  }
  .banner-btn-right {
    right: 10px;
  }

  .banner-btn {
    position: absolute;
    cursor: pointer;
    top: 40%;
    z-index: 1;
  }
}
.tag-sec {
  width: 100%;
  position: absolute;
  text-align: center;
  padding: 0%;
  color: #f1f1f1;
  height: auto;
  display: inline-block;
  .read-more {
    margin-top: 30px;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
  }
  > h6 {
    width: 650px;
    margin: 0 auto;
    font-size: 15px;
    padding: 25px 0 10px;
    font-family: roboto-lyt;
    color: #fff;
  }
  .banner-name-2 {
    display: inline-block;
    line-height: 0;
    color: #0091ea;
    font-size: 80px;
    padding: 0;
    margin: 0;
    line-height: normal;
    font-family: roboto-med;
    > span {
      color: #fff;
      font-family: microsoft yahei, sans-serif;
      font-weight: 400;
    }
  }
  .banner-name {
    display: inline-block;
    line-height: 0;
    font-size: 80px;
    margin: 0;
    line-height: normal;
    color: #0091ea;
    text-align: left;
    font-family: microsoft yahei, sans-serif;
    font-weight: 700;
    > span {
      color: #fff;
      font-family: microsoft yahei, sans-serif;
      font-weight: 400;
    }
  }
  .banner-text {
    width: 100%;
    margin: 20% auto 0;
    font-family: roboto-reg;
    text-align: center;
  }
}

.ph1 {
  width: 600px;
  bottom: 0;
  left: 15%;
}
.ph3 {
  width: 600px;
  bottom: 0;
  left: 50%;
}

.ph2 {
  width: 700px;
  bottom: 0;
  left: 30%;
}

@media (max-width: 1650px) {
  .text3-image .laptop {
    left: 14%;
  }
  .text3-image .table {
    left: 10%;
  }
  .slide3-icon {
    left: 0;
  }
}

@media (max-width: 1530px) {
  .slide2-text .slide2-name > span {
    font-size: 60px;
  }
  .slide2-text > p {
    font-size: 45px;
  }
}

@media (max-width: 1400px) {
  .text3-image .laptop {
    left: 26%;
    width: 70%;
  }
  .text3-image .table {
    top: 320px;
    width: 80%;
    left: 22%;
  }
  .slide3-title {
    font-size: 50px;
  }
  .slide3-icon {
    top: 50px;
    width: 90%;
  }
  .slide4-title {
    font-size: 50px;
  }
}

@media (max-width: 1366px) {
  .ph2 {
    width: 500px;
    left: 32%;
  }
  .ph3 {
    width: 400px;
    left: 55%;
  }
  .ph1 {
    width: 400px;
  }
  .banner-section {
    height: 800px;
  }
}

@media (max-width: 1350px) {
  .tag-sec {
    .banner-name {
      font-size: 65px;
    }
    .banner-name-2 {
      font-size: 65px;
    }
  }
}

@media (max-width: 1275px) {
  .slide2-text .slide2-name > span {
    font-size: 55px;
  }
  .slide2-text > p {
    font-size: 40px;
  }
}

@media (max-width: 1199px) {
  .creation {
    height: 420px;
  }
  .text3-image .laptop {
    width: 50%;
    left: 35%;
  }
  .text3-image .table {
    top: 220px;
    width: 60%;
    left: 30%;
  }
  .slide3-icon {
    top: 10px;
    width: 80%;
    left: 5%;
  }
  .ph2 {
    width: 400px;
    left: 35%;
  }
  .ph3 {
    width: 300px;
    left: 60%;
  }
  .ph1 {
    width: 300px;
    left: 20%;
  }

  .icon9 {
    top: 400px;
    right: 135px;
    width: 87px;
    height: 63px;
  }
  .icon8 {
    top: 420px;
    right: 50px;
    width: 56px;
    height: 56px;
  }
  .icon7 {
    top: 520px;
    right: 150px;
    width: 42px;
    height: 59px;
  }
  .icon5 {
    top: 440px;
    left: 210px;
    width: 103px;
    height: 69px;
  }
  .icon4 {
    top: 430px;
    left: 110px;
    width: 63px;
    height: 57px;
  }
  .icon3 {
    top: 150px;
    left: 630px;
    width: 76px;
    height: 82px;
  }
  .icon2 {
    top: 150px;
    left: 340px;
    width: 46px;
    height: 35px;
  }
  .icon1 {
    top: 150px;
    left: 200px;
    width: 84px;
    height: 62px;
  }
  .banner-section {
    height: 674px;
  }

  .tag-sec {
    h6 {
      width: 450px;
      font-size: 12px;
    }
    .banner-name {
      font-size: 60px;
      padding-top: 25px;
    }
    .banner-name-2 {
      font-size: 60px;
    }
  }
}

@media (max-width: 1150px) {
  .slide2-text .slide2-name > span {
    font-size: 45px;
  }
  .slide2-text > p {
    font-size: 35px;
  }
}

@media (max-width: 1120px) {
  .slide2-text {
    margin-top: 30px;
    .slide2-name > span {
      font-size: 50px;
    }
    > p {
      font-size: 30px;
    }
  }
}

@media (max-width: 1099px) {
  .text3-image .table {
    top: 140px;
  }
  .slide3-icon {
    top: 20px;
    left: 5%;
  }
}

@media (max-width: 1000px) {
  .ph2 {
    left: 30%;
  }
  .ph3 {
    left: 57%;
  }
  .ph1 {
    left: 12%;
  }
}

@media (max-width: 999px) {
  .creation {
    height: 460px;
  }
  .creation-col-6 {
    width: 50%;
  }
}

@media (max-width: 991px) {
  hr {
    width: 500px;
    height: 2px;
  }
  .slide2-title {
    margin-top: 8%;
  }
  .slide4-title {
    font-size: 30px;
  }
  .text4-text {
    margin-top: 90px;
  }
  .icon9 {
    top: 350px;
    right: 155px;
    width: 77px;
    height: 53px;
  }
  .icon8 {
    top: 370px;
    right: 50px;
    width: 46px;
    height: 46px;
  }
  .icon7 {
    top: 430px;
    right: 150px;
    width: 42px;
    height: 59px;
  }
  .icon5 {
    top: 400px;
    left: 250px;
    width: 103px;
    height: 69px;
  }
  .icon4 {
    top: 380px;
    left: 120px;
    width: 63px;
    height: 57px;
  }
  .icon3 {
    top: 100px;
    left: 730px;
    width: 76px;
    height: 82px;
  }
  .icon2 {
    top: 100px;
    left: 340px;
    width: 46px;
    height: 35px;
  }
  .icon1 {
    top: 100px;
    left: 200px;
    width: 84px;
    height: 62px;
  }
  .banner-section {
    height: 557px;
  }
  .tag-sec {
    .banner-name {
      padding-top: 20px;
      font-size: 45px;
    }
    .banner-name-2 {
      font-size: 45px;
    }
    h6 {
      width: 450px;
      font-size: 12px;
    }
  }
}

@media (max-width: 950px) {
  .text3-image .laptop {
    top: 30px;
  }
}

@media (max-width: 900px) {
  .slide2-text {
    margin-top: 35px;
    .slide2-name > span {
      font-size: 35px;
    }
    > p {
      font-size: 25px;
    }
  }
}

@media (max-width: 780px) {
  .ph2 {
    width: 300px;
  }
  .ph3 {
    width: 250px;
  }
  .ph1 {
    width: 250px;
  }
}

@media (max-width: 770px) {
  .slide2-text {
    margin-top: 25px;
    .slide2-name > span {
      font-size: 30px;
    }
    > p {
      font-size: 20px;
    }
  }
}

@media (max-width: 767px) {
  hr {
    width: 400px;
    height: 2px;
    width: 350px;
    line-height: 1;
  }
  .text3-image .table {
    top: 140px;
    left: 30%;
  }
  .slide3-title {
    font-size: 35px;
  }
  .text3-text {
    margin-top: 70px;
  }
  .slide3-icon {
    left: 10%;
  }
  .text4-text {
    margin-top: 90px;
  }
  .icon9 {
    top: 250px;
    right: 105px;
    width: 67px;
    height: 43px;
  }
  .icon8 {
    top: 250px;
    right: 30px;
    width: 36px;
    height: 36px;
  }
  .icon7 {
    top: 360px;
    right: 150px;
    width: 32px;
    height: 49px;
  }
  .icon5 {
    position: absolute;
    top: 320px;
    left: 120px;
    width: 83px;
    height: 49px;
  }
  .icon4 {
    top: 280px;
    left: 100px;
    width: 43px;
    height: 37px;
  }
  .icon3 {
    top: 60px;
    left: 530px;
    width: 46px;
    height: 42px;
  }
  .icon2 {
    top: 40px;
    left: 190px;
    width: 36px;
    height: 25px;
  }
  .icon1 {
    top: 100px;
    left: 100px;
    width: 64px;
    height: 42px;
  }
  .banner-section {
    height: 431px;
  }
  .tag-sec {
    .banner-name {
      font-size: 40px;
    }
    h6 {
      width: 450px;
      font-size: 12px;
    }
  }
}

@media (max-width: 740px) {
  .creation {
    height: 410px;
    .text-content {
      padding: 5px 20px;
    }
  }
  .padding-side {
    padding: 0 5px;
  }
}

@media (max-width: 690px) {
  .slide2-title {
    margin-top: 12%;
  }
}

@media (max-width: 680px) {
  .ph2 {
    width: 250px;
  }
  .ph3 {
    width: 200px;
  }
  .ph1 {
    width: 200px;
  }
}

@media (max-width: 650px) {
  .slide3-icon {
    bottom: 10px;
    left: 4%;
    width: 90%;
  }
  .slide2-text {
    margin-top: 20px;
    .slide2-name > span {
      font-size: 25px;
    }
    > p {
      font-size: 15px;
    }
  }
}

@media (max-width: 575px) {
  hr {
    width: 250px;
    height: 1px;
    line-height: 1;
  }

  .text3-image .table {
    top: 110px;
  }
  .slide3-title {
    font-size: 20px;
  }
  .slide3-icon {
    left: 0;
  }
  .ph2 {
    width: 150px;
    left: 38%;
  }
  .ph3 {
    width: 100px;
  }
  .ph1 {
    width: 100px;
    left: 30%;
  }
  .icon9 {
    top: 230px;
    right: 50px;
    width: 37px;
    height: 43px;
  }
  .icon8 {
    top: 220px;
    right: 20px;
    width: 26px;
    height: 26px;
  }
  .icon7 {
    top: 290px;
    right: 20px;
    width: 31px;
    height: 28px;
  }
  .icon5 {
    top: 240px;
    left: 90px;
    width: 50px;
    height: 35px;
  }
  .icon4 {
    top: 220px;
    left: 40px;
    width: 28px;
    height: 22px;
  }
  .icon3 {
    top: 60px;
    left: 280px;
    width: 26px;
    height: 22px;
  }
  .icon2 {
    top: 40px;
    left: 80px;
    width: 26px;
    height: 15px;
  }
  .icon1 {
    top: 90px;
    left: 30px;
    width: 34px;
    height: 22px;
  }
  .banner-section {
    height: 323px;
  }
}

@media (max-width: 530px) {
  .ph2 {
    left: 32%;
  }
  .slide2-title {
    margin-top: 20%;
  }
  .ph1 {
    left: 20%;
  }
}

@media (max-width: 520px) {
  .slide2-text {
    margin-top: 15px;
    .slide2-name > span {
      font-size: 18px;
    }
    > p {
      font-size: 15px;
    }
  }
}

@media (max-width: 450px) {
  .slide3-icon {
    top: 50px;
  }
}

@media (max-width: 425px) {
  .text3-image .table {
    top: 90px;
  }
  .slide4-title {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .creation {
    height: auto;
  }
  .creation-col-6 {
    width: 100%;
  }
  .slide2-text {
    margin-top: 10px;
    .slide2-name > span {
      font-size: 20px;
    }
    > p {
      font-size: 10px;
    }
  }
  .slide2-title {
    margin-top: 30%;
  }
  .text4-text {
    margin-top: 90px;
  }
}
@media (max-width: 375px) {
  .text3-image .laptop {
    width: 80%;
    left: 23%;
  }
  .text3-image .table {
    top: 105px;
    width: 95%;
    left: 18%;
  }
  .slide3-icon {
    top: 80px;
  }
  .slide4-image {
    width: 70%;
  }
}

@media (max-width: 320px) {
  .slide4-image {
    width: 90%;
  }
}

hr {
  width: 700px;
  background-color: #0091ea;
  border: 1px solid #0091ea;
  box-sizing: content-box;
  margin: 0 auto;
  height: 0;
  overflow: visible;
}
.ph1,
.ph2,
.ph3 {
  position: absolute;
}
</style>

<style>
.icon1 {
  top: 100px;
  left: 200px;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
}
.icon1,
.icon2,
.icon3,
.icon4,
.icon5,
.icon6,
.icon7,
.icon8,
.icon9,
.icon10 {
  position: absolute;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 575px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-6 {
  width: 50%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  color: #333;
  font-weight: 400;
}
.icon2 {
  top: 120px;
  left: 580px;
}
.icon3 {
  top: 180px;
  right: 230px;
}
.icon4 {
  top: 500px;
  left: 100px;
}
.icon5 {
  top: 650px;
  left: 300px;
}
.icon7 {
  top: 700px;
  right: 350px;
}
.icon8 {
  top: 800px;
  right: 105px;
}
.icon9 {
  top: 550px;
  right: 205px;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.no-padding {
  padding: 0;
}
.col-10 {
  width: 83.33%;
}
.col-2 {
  width: 16.67%;
}
.col-5 {
  width: 41.67%;
}
.col-9 {
  width: 75%;
}
.col-3 {
  width: 25%;
}
.padding-side {
  margin-top: 20px;
  padding: 0 15px;
}
.col-4 {
  width: 33.33%;
}
.h5 {
  font-size: 16px;
}
.row {
  display: block;
}
.over-hidden {
  overflow: hidden;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 575px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
    max-width: 750px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

p {
  margin: 0 0 1.5em;
  padding: 0;
  line-height: 1.75em;
}
</style>