<template>
  <div>
    <div class="root" :style="{'height':height+'px'}" :class="{noShow:selectIndex!=0}">
      <div style="width:100%;">
        <div class="top slideInUp wow"> {{ $t('十年沉淀，匠心巨造') }}</div>
        <div class="top2 slideInUp wow"> {{ $t('领跑娱乐科技产业') }}</div>

      </div>

    </div>
    <div class="lottery" :class="{noShow:selectIndex!=1}">
      <img src="@/assets/pc/product/lottery.png" class="wow slideInLeft" alt="">
      <div class="title-wraper">
        <div class="tip1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('完美呈现')}}</div>
        <div class="tip2 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('数字彩票')}}</div>
        <div class="tip3 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.8s">{{$t('一站式技术解决方案')}}</div>
        <div class="tip4 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.1s">{{$t('多个版本尽情玩乐')}}</div>

        <div class="more wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.4s" @click="showMoreClick()">{{$t('了解更多')}}</div>
      </div>
    </div>
    <div class="slot" :class="{noShow:selectIndex!=2}">
      <div class="title-wraper">
        <div class="tip1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('完美呈现')}}</div>
        <div class="tip2 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('棋牌电游')}}</div>
        <div class="tip3 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.8s">{{$t('7*24小时客服运维保障')}}</div>
        <div class="tip4 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.1s">{{$t('游戏大厅自定义')}}</div>

        <div class="more wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.4s" @click="showMoreClick()">{{$t('了解更多')}}</div>
      </div>
      <img src="@/assets/pc/product/slot.png" class="wow slideInLeft" alt="">

    </div>
    <div class="lottery" :class="{noShow:selectIndex!=3}">
      <img src="@/assets/pc/product/sport.png" class="wow slideInLeft" alt="">
      <div class="title-wraper">
        <div class="tip1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('完美呈现')}}</div>
        <div class="tip2 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('体育电竞')}}</div>
        <div class="tip3 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.8s">{{$t('高端的私人品牌定制')}}</div>
        <div class="tip4 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.1s">{{$t('等你来战')}}</div>

        <div class="more wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.4s" @click="showMoreClick()">{{$t('了解更多')}}</div>
      </div>
    </div>
    <div class="slot" :class="{noShow:selectIndex!=4}">
      <div class="title-wraper">
        <div class="tip1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('完美呈现')}}</div>
        <div class="tip2 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('视讯棋牌')}}</div>
        <div class="tip3 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.8s">{{$t('独家实验赌场+真人棋牌游戏')}}</div>
        <div class="tip4 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.1s">{{$t('K8打造最新独创真人融合棋牌电游风')}}</div>

        <div class="more wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="2.4s" @click="showMoreClick()">{{$t('了解更多')}}</div>
      </div>
      <img src="@/assets/pc/product/casino.png" class="wow slideInLeft" alt="">

    </div>
    <div class="float">
      <div v-for="(item,index) in floatList" :key="index" class="floatList">
        <div class="icon" :class="{active:selectIndex==index,other:selectIndex>0}" @click="itemClick(index)">
          <img :src="item.img" alt="">
        </div>
        <div :class="{otherFont:selectIndex>0}">{{$t(item.label)}}</div>
      </div>
    </div>

    <div v-if="showMore" class="showMore">
      <img src="@/assets/pc/close.png" alt="" class="close" @click="showMore=false">

      <div v-if="selectIndex==1" class="lotteryInfo">
        <div class="top1">

          <img src="@/assets/pc/product/lottery/top.png" class="wow slideInLeft" alt="">
          <div class="title-wraper">
            <div style="font-size:54px;font-weight:bold;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('数字彩票')}}</div>
            <div style="font-size:40px;font-weight:bold;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('全渠道的棋牌电游游戏供应商')}}</div>
            <div class="line black wow fadeInUp"></div>
            <div style="width:480px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home14')}}</div>

          </div>

        </div>
        <div class="top3">
          <div style="height:50%;">
            <div class="title-wraper">
              <div style="font-size:40px;font-weight:bold;color:#FF2A40;margin-top:66px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('数字彩票')}}</div>
              <div class="line  wow fadeInUp" style="background-color:#FF2A40;"></div>

              <div style="font-size:16px;font-weight:400;color:#333;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('美女主播现场24小时不间断视频开奖等你来撩')}}</div>
              <div style="width:500px;font-weight:400;margin-top:262px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.8s">{{$t('home15')}}</div>

            </div>
            <img src="@/assets/pc/product/lottery/center.png" class="wow slideInLeft" alt="">

          </div>
          <div class="top4" style="height:50%;">

            <img src="@/assets/pc/product/lottery/phone.png" class="wow slideInLeft" alt="">
            <div class="title-wraper">
              <div style="font-size:40px;font-weight:bold;margin-top:66px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('智能微投彩票')}}</div>
              <div class="line black wow fadeInUp" style="background-color:#fff;"></div>

              <div style="font-size:40px;font-weight:bold;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('当下最领先，未来最主流的跨平台彩票游戏系统')}}</div>
              <div style="width:500px;font-weight:400;margin-top:87px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home16')}}</div>

            </div>

          </div>
        </div>

      </div>
      <div v-if="selectIndex==2" class="lotteryInfo">
        <div class="top5">

          <img src="@/assets/pc/card/live_one.png" style="" class="wow slideInLeft" alt="">
          <div class="title-wraper">
            <div style="font-size:54px;font-weight:bold;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('棋牌电游')}}</div>
            <div style="font-size:40px;font-weight:bold;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('全渠道的棋牌电游游戏供应商')}}</div>
            <div class="line black wow fadeInUp"></div>
            <div style="width:523px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home17')}}</div>

          </div>

        </div>
        <div class="top6">

          <div class="title-wraper">
            <div style="font-size:40px;font-weight:bold;;margin-top:131px;color:#333;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('棋牌竞技')}}</div>

            <div style="font-size:16px;font-weight:400;color:#333;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s" v-html="$t('home18')"></div>

          </div>
          <img src="@/assets/pc/card/two_man.png" style="top:76px;height:70%;left:40%;" class="wow slideInLeft" alt="">
        </div>
        <div class="top7">

          <img src="@/assets/pc/card/two_girl.png" class="wow slideInLeft" alt="">
          <div class="title-wraper">
            <div style="font-size:40px;font-weight:bold;margin-top:66px;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('街机电玩')}}</div>

            <div style="font-size:16px;font-weight:400;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s" v-html="$t('home19')"></div>

          </div>

        </div>
        <div class="top8">

          <div class="title-wraper">
            <div style="font-size:40px;font-weight:bold;;margin-top:131px;color:#333;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('老虎机')}}</div>

            <div style="font-size:16px;font-weight:400;color:#333;" class="wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s" v-html="$t('home20')"></div>

          </div>
          <img src="@/assets/pc/card/three_two.png" style="top:51px;" class="wow slideInLeft" alt="">
        </div>
        <div class="top9">

          <img src="@/assets/pc/card/four_one.png" class="wow slideInLeft" alt="">
          <div class="title-wraper">
            <div style="font-size:40px;font-weight:bold;margin-top:66px;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('捕鱼狩猎')}}</div>

            <div style="font-size:16px;font-weight:400;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s" v-html="$t('home21')"></div>

          </div>

        </div>
      </div>
      <div v-if="selectIndex==3" class="lotteryInfo">
        <div class="top10">

          <img src="@/assets/pc/sport/tiyu_01.png" style="" class="wow slideInLeft" alt="">
          <div class="title-wraper">
            <div style="font-size:54px;font-weight:bold;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">K8{{$t('体育电竞')}}</div>
            <div style="font-size:40px;font-weight:bold;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('3D化游戏图形')}}</div>
            <div class="line black wow fadeInUp"></div>
            <div style="width:481px;font-weight:400;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home22')}}</div>

          </div>

        </div>
        <div class="top11">
          <div style="font-size:40px;font-weight:bold;padding-top:75px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('开启互动直播体育竞技新时代')}}</div>

          <div style="width:593px;font-weight:400;margin:24px auto;font-size:14px;text-align:left;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home23')}}</div>
          <img src="@/assets/pc/sport/game.png" class="wow slideInLeft" alt="" style="margin-top:69px;width:793px;">

        </div>
        <div class="top12">
          <div style="font-size:40px;font-weight:bold;padding-top:393px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('全新的电子竞技投注系统')}}</div>

          <div style="width:593px;font-weight:400;margin:24px auto;font-size:14px;text-align:left;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home24')}}</div>

        </div>
      </div>
      <div v-if="selectIndex==4" class="lotteryInfo">
        <div class="top13">
          <img src="@/assets/pc/casino/shixun_01.png" class="wow slideInLeft" alt="" style="margin-top:104px;height:calc(100% - 104px);">
          <div class="title-wraper">
            <div style="font-size:54px;font-weight:bold;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('真人视讯棋牌')}}</div>
            <div style="font-size:40px;font-weight:bold;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('独家实验赌场+真人棋牌游戏')}}</div>
            <div class="line black wow fadeInUp"></div>
            <div style="width:481px;font-weight:400;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home25')}}</div>

          </div>
        </div>
        <div class="top14">
          <div style="font-size:40px;font-weight:bold;padding-top:43px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('产品特色')}}</div>

          <div style="margin-top:52px;" class="num wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">01</div>
          <div class="sxqp wow slideInLeft">
            <div style="font-size:40px;font-weight:bold;margin-top:0px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('真人实训棋牌')}}</div>
            <div style="font-size:14px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home26')}}</div>
            <div class="line red wow fadeInUp" style="margin:20px auto;"></div>

          </div>

          <div class="flex_r" style="margin-top:120px;">
            <div style="width:240px;text-align:left;">
              <div style="margin-top:52px;" class="num wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">02</div>
              <div style="font-size:40px;font-weight:bold;margin-top:0px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('专属定制UI')}}</div>
              <div style="font-size:14px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home27')}}</div>
              <div class="line red wow fadeInUp"></div>

            </div>
            <div style="margin-left:99px;margin-top:30px;">
              <img src="@/assets/pc/casino/shouji_01.png" class="wow slideInLeft" alt="" style="width:214px;">
              <img src="@/assets/pc/casino/shouji_02.png" class="wow slideInLeft" alt="" style="width:214px;margin-left:49px;">
              <img src="@/assets/pc/casino/shouji_03.png" class="wow slideInLeft" alt="" style="width:214px;margin-left:49px;">

            </div>

          </div>
          <div class="flex_r" style="margin-top:150px;">
            <img src="@/assets/pc/casino/dybjl.png" class="wow slideInLeft" alt="" style="width:793px;">
            <div style="width:260px;text-align:left;margin-left:51px;margin-top:58px;">
              <div style="margin-top:52px;" class="num wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">03</div>
              <div style="font-size:40px;font-weight:bold;margin-top:0px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('独有百家乐')}}</div>
              <div style="font-size:14px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home28')}}</div>
              <div class="line red wow fadeInUp"></div>

            </div>
          </div>

          <div style="margin-top:126px;" class="num wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">04</div>
          <div style="font-size:40px;font-weight:bold;margin-top:0px;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('迷人荷官主持人')}}</div>
          <div style="font-size:14px;font-weight:400;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.5s">{{$t('home29')}}</div>
          <div class="line red wow fadeInUp" style="margin:20px auto;"></div>
          <img src="@/assets/pc/casino/zcr.png" class="wow slideInLeft" alt="" style="width:1036px;margin-top:51px;">

        </div>
        <div class="top15 ">
          <div style="font-size:40px;font-weight:bold;padding-top:50px;color:#fff;" class=" wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="1.2s">{{$t('游戏种类')}}</div>

          <div class="flex_r">

            <div style="display:inline-block;text-align:left;">
              <div class="flexD">
                <img src="@/assets/pc/casino/jmbjl.png" class="wow slideInLeft" alt="" style="width:120px;">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;">{{$t('竞咪百家乐')}}</span>
              </div>

              <div style="margin-top:130px;" class="flexD">
                <img src="@/assets/pc/casino/lunpan.png" class="wow slideInLeft" alt="" style="width:120px;">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;height:120px;line-height:120px;display:inline-block;">{{$t('轮盘')}}</span>
              </div>

              <div style="margin-top:130px;" class="flexD">
                <img src="@/assets/pc/casino/dtbjl.png" class="wow slideInLeft" alt="" style="width:120px;">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;">{{$t('多台百家乐')}}</span>
              </div>

            </div>
            <img src="@/assets/pc/casino/three_phone.png" class="wow slideInLeft" alt="" style="width:407px;margin:0 127px;">
            <div style="display:inline-block;text-align:right;">
              <div class="flexD">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('龙虎')}}</span>

                <img src="@/assets/pc/casino/longhu.png" class="wow slideInLeft" alt="" style="width:120px;">
              </div>

              <div style="margin-top:130px;" class="flexD">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;">{{$t('百家乐')}}</span>

                <img src="@/assets/pc/casino/zl_05.png" class="wow slideInLeft" alt="" style="width:120px;">
              </div>

              <div style="margin-top:130px;" class="flexD">
                <span class="wow slideInLeft" style="color:#fff;font-size:20px;font-weight:bold;">&nbsp;&nbsp;{{$t('骰宝')}}</span>

                <img src="@/assets/pc/casino/sb.png" class="wow slideInLeft" alt="" style="width:120px;">
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";
import {
  WOW
} from 'wowjs';
import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper";
import { Autoplay } from "swiper";

import "swiper/swiper.css";
export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      height: '',
      selectIndex: 0,
      showMore: false,
      floatList: [{ label: 'Gaming', img: require('@/assets/pc/product/icon1.png') },
      { label: '数字彩票', img: require('@/assets/pc/product/icon2.png') },
      { label: '棋牌电游', img: require('@/assets/pc/product/icon3.png') },
      { label: '体育电竞', img: require('@/assets/pc/product/icon4.png') },
      { label: '视讯棋牌', img: require('@/assets/pc/product/icon5.png') }]
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {

    this.height = document.body.clientWidth / 1919 * 882
    window.onresize = () => {
      return (() => {
        this.height = document.body.clientWidth / 1919 * 882

      })()
    };

  },
  methods: {
    showMoreClick() {
      this.showMore = true
      // this.$nextTick(() => { //页面渲染完，在执行
      // 	let wow = new WOW({
      // 		live: false
      // 	})
      // 	wow.init()
      // })
    },
    itemClick(index) {
      this.selectIndex = index

      this.$nextTick(() => { //页面渲染完，在执行
        setTimeout(() => {
          let wow = new WOW({
            live: false
          })
          wow.init()
        }, 50);

      })
    }
  },
};
</script>

<style scoped lang="scss">
.showMore {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 300;
}
.num {
  font-size: 100px;
  font-weight: bold;
  color: #ff2a40;
}

.flex_r {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexD {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sxqp {
  background: url(@/assets/pc/casino/sxqp.png) center no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 1073px;
  height: 485px;
  text-align: center;
  margin: 0 auto;
}
.lotteryInfo {
  .top15 {
    background: url(@/assets/pc/casino/zuidb_bg2.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 941px;
    text-align: center;
  }
  .top14 {
    background: url(@/assets/pc/casino/shixun_bjd.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 2739px;
    text-align: center;
  }
  .top13 {
    background: url(@/assets/pc/casino/shixun_bg.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 941px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 164px;
      width: calc(50% - 164px);
    }
  }
  .top12 {
    background: url(@/assets/pc/sport/tiyu_03.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 941px;
    text-align: center;
  }
  .top11 {
    background: url(@/assets/pc/sport/tiyu_bg2.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 879px;
    text-align: center;
  }
  .top10 {
    background: url(@/assets/pc/sport/tiyu_bg.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 941px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 164px;
      width: calc(50% - 164px);
    }
    img {
      width: auto;
      max-height: 884px;
      height: 80%;
      right: 50%;
      position: absolute;
      z-index: 100;
      top: 10%;
    }
  }
  .top9 {
    background: url(@/assets/pc/card/qipai_bg5.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 469px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 0;
      width: 45%;
    }
    img {
      width: auto;
      max-height: 884px;
      height: 80%;
      right: 50%;
      position: absolute;
      z-index: 100;
      top: 10%;
    }
  }
  .top8 {
    background: url(@/assets/pc/card/qipai_bg4.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 469px;
    .title-wraper {
      color: #fff;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    img {
      width: auto;
      max-height: 753px;
      height: 75%;
      left: 50%;
      position: absolute;
      z-index: 100;
      top: 189px;
    }
  }
  .top7 {
    background: url(@/assets/pc/card/qipai_bg3.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 469px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 0;
      width: 45%;
    }
    img {
      width: auto;
      max-height: 884px;
      height: 80%;
      right: 50%;
      position: absolute;
      z-index: 100;
      top: 10%;
    }
  }
  .top6 {
    background: url(@/assets/pc/card/qipai_bg2.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 469px;
    .title-wraper {
      color: #fff;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    img {
      width: auto;
      max-height: 753px;
      height: 80%;
      left: 55%;
      position: absolute;
      z-index: 100;
      top: 189px;
    }
  }
  .top5 {
    background: url(@/assets/pc/card/qipai_bgTop.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 941px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 164px;
      width: calc(50% - 164px);
    }
    img {
      width: auto;
      max-height: 884px;
      height: 80%;
      right: 55%;
      position: absolute;
      z-index: 100;
      top: 10%;
    }
  }
  .top3 {
    background: url(@/assets/pc/product/lottery/lotteryBg2.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 1883px;
    .title-wraper {
      width: 60%;
      color: #fff;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    img {
      width: auto;
      max-height: 753px;
      height: 80%;
      left: 50%;
      position: absolute;
      z-index: 100;
      top: 189px;
    }
  }
  .top1 {
    background: url(@/assets/pc/product/lottery/lotteryBg1.png) center no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 940px;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 164px;
      width: calc(50% - 164px);
    }
    img {
      width: auto;
      max-height: 884px;
      height: 80%;
      right: 50%;
      position: absolute;
      z-index: 100;
      top: 10%;
    }
  }
  .top4 {
    position: relative;
    width: 100%;
    .title-wraper {
      position: absolute;
      color: #fff;
      display: inline-block;
      height: auto;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      right: 164px;
      width: calc(50% - 164px);
    }
    img {
      width: auto;
      left: 0;
      height: 471px;

      position: absolute;
      z-index: 100;
      top: 280px;
      left: 8%;
    }
  }
}
.root {
  position: relative;
  margin-top: 100px;
  //  height: 882px;
  background: url(@/assets/pc/product/cp_bg.png) center no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;

  color: #fff;
}
.slot {
  margin-top: 100px;
  height: 882px;
  background-color: #fff;
  //display: flex;
  //@at-rootalign-items: center;

  .title-wraper {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: auto;
    max-height: 884px;
    height: 80%;
    left: 50%;
    position: absolute;
    z-index: 100;
    top: calc(10% + 100px);
  }
}
.lottery {
  margin-top: 100px;
  height: 882px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  .title-wraper {
    position: absolute;
    display: inline-block;
    height: auto;
    top: 50%;
    transform: translateY(-50%) translateZ(0);
    right: 164px;
    width: calc(50% - 164px);
  }

  img {
    width: auto;
    max-height: 884px;
    height: 80%;
    right: 50%;
    position: absolute;
    z-index: 100;
    top: 100px;
    //transform: translateY(-50%) translateZ(0);
  }
}

.tip1 {
  color: #a2a2a2;
  font-size: 22px;
  font-weight: bold;
}
.tip2 {
  color: #333333;
  font-size: 92px;
  font-weight: bold;
}
.tip3 {
  color: #0091ea;
  font-weight: bold;

  font-size: 34px;
}
.tip4 {
  color: #a2a2a2;
  font-size: 22px;
  font-weight: 400;
  margin-top: 15px;
}
.top {
  font-size: 82px;
  font-weight: bold;
  margin-top: -20px;
  padding-left: 20%;
}
.top2 {
  font-size: 46px;
  font-weight: bold;
  padding-left: 20%;
  margin-top: 20px;
}
.float {
  position: fixed;
  right: 44px;
  top: calc(50vh - 230px);
}

.floatList {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  .icon {
    width: 60px;
    height: 60px;
    border: 1px solid #fff;
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .icon:hover {
    background-color: #0091ea;
  }
  img {
    width: 60px;
  }
}
.line {
  height: 4px;
  width: 78px;
  margin: 29px 0;
}
.black {
  background-color: #000;
}
.other {
  background-color: #2d2d2d;
}
.red {
  background-color: #ff2a40;
}
.otherFont {
  color: #333333;
}
.noShow {
  display: none;
}
.active {
  background-color: #0091ea;
}
.more {
  width: 236px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  background-color: #0091ea;
  text-align: center;
  line-height: 50px;
  font-weight: 400;
  margin-top: 40px;
  cursor: pointer;
}
.close {
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 999;
  cursor: pointer;
  width: 60px;
}
</style>